$title_font: outfit, sans-serif;

.sidebar {
  background-color: #011025;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 300px;
  color: #f8faff;
  -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  padding-left: 5px;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;

  &__title {
    text-transform: uppercase;
    font-family: $title_font;
    color: #fff;
    opacity: 1;
    font-weight: 700 !important;
    font-size: 14px !important;
    margin: 0 !important;
    padding: 10px 20px;
  }

  &__break {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }

  &__item {
    font-family: $title_font;
    align-content: center;
    margin-bottom: 3px;
    transition: all 0.2s;
    padding-right: 30px;
    font-weight: 300;

    &--link {
      width: 100%;
      height: 45px;
      padding: 0 20px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      font-size: 15px;
      font-family: $title_font;
      text-decoration: none;
      color: #ffff;

      &:hover {
        text-decoration: none;
        color: #ffff;
        background-color: #000914;
      }

      &:focus {
        text-decoration: none;
        font-weight: 600;
      }
      &--disabled {
        width: 100%;
        height: 45px;
        padding: 0 20px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        font-size: 15px;
        font-family: $title_font;
        text-decoration: none;
        color: #ffff;
        pointer-events: none;
        opacity: 0.4;
      }
      &-visited {
        width: 100%;
        height: 45px;
        padding: 0 20px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        font-size: 15px;
        font-family: $title_font;
        text-decoration: none;
        color: #ffff;

        &:hover {
          text-decoration: none;
          color: #ffff;
          background-color: #000914;
        }
      }
      &__image {
        height: 24px;
        width: 24px;
        margin-right: 20px;
      }
    }

    &--link-active {
      width: 100%;
      height: 45px;
      padding: 0 20px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 15px;
      font-family: $title_font;
      text-decoration: none;
      color: #ffff;
      background-color: #000914;
    }
  }

  &__header {
    width: 100%;
    height: 10%;
    padding-bottom: 20px;
    padding-top: 35px;
    padding-left: 10px;
    img {
      width: 168px;
      height: 41px;
    }
  }
}

.sidebar-scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;

  &:vertical {
    width: 4px;
  }

  &-thumb {
    border-radius: 3px;
    border: none;
    background-color: #02193b;
    overflow: hidden;
  }

  &-track {
    background-color: #000914;
    border-radius: 0 0 5px 5px;
  }
}
