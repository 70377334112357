#root {
  display: grid;
  grid-template-areas:
    "nav toggle header"
    "nav toggle article";
  grid-template-rows: 35px auto;
  grid-template-columns: 290px 20px auto;
  grid-row-gap: 10px;
  height: 100vh;
  margin: 0;
  box-sizing: border-box;
}

#header {
  grid-area: header;
  grid-row: 1;
  grid-column: 3;
}

#content {
  grid-area: article;
  grid-row: 2;
  grid-column: 3;
  height: 100vh;
}

#sidebar {
  grid-area: nav;
  grid-row: 1;
  grid-column: 1;
}

#sidebar-toggle {
  grid-area: toggle;
  grid-row: 1;
  grid-column: 2;
  -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
