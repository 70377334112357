body main div button.toggle div svg.sidebar-toggle-icon {
  transform: rotate(0deg);
  -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}

body main div.toggle-collapsed button.toggle div svg.sidebar-toggle-icon {
  transform: rotate(180deg);
  -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}

.toggle {
  border: none;
  border-radius: 0 0 5px 0;
  width: 60px;
  height: 50px;
  position: fixed;
  background-color: #f8faff;

  &:focus {
    outline: none;
  }
}

.collapsed {
  margin-left: -18rem;
}

.toggle-collapsed {
  margin-left: -18rem;
}
