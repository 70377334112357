/* Colors */

/*Code*/
$code-text-color: #333333;
$code-background-color: #f8faff;

/*Content Title*/
$title-color: #044f68;
$subtitle-color: #044f68;
$sub-subtitle-color: #707070;
