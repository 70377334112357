.checkmark {
  width: 20px;
  padding: 0;
  margin: 0;
}

.ellipse {
  width: 6px;
  padding: 0;
  margin: 0;
}

.providers-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;

  & thead th {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-top: 1px solid #c9c9c9;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 30px;
    padding: 6px 20px !important;
  }

  & tbody tr {
    background: transparent !important;
    border: 1px solid #c9c9c9;
  }

  & tbody:nth-child(even) {
    background: #f8faff 0% 0% no-repeat padding-box;
    border: 1px solid #c9c9c9;
  }

  & th,
  & td {
    text-align: center;
    font-size: 12px;
  }

  & th:first-child,
  & td:first-child {
    vertical-align: middle !important;
    text-align: left;
    font-size: 12px;
  }

  & td {
    color: #707070;
  }
}

.filter-dropdown {
  width: 256px !important;
  height: 44px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c7c7c7 !important;
  border-radius: 4px;
  opacity: 1;
  color: #707070 !important;
  font-size: 12px !important;
  padding: 4px 10px;
  margin-bottom: 10px;
  margin-right: 10px;

  img {
    width: 19px;
    margin: 0;
  }
}

.dropdown {
  width: 256px !important;
}

.dropdown-menu {
  margin-top: -10px !important;
}

.dropdown-item {
  padding: 0 !important;
}

.dropdown-item:active {
  background-color: transparent !important;
}

.form-check {
  padding-left: 0 !important;
}

.form-check-label {
  font-size: 12px !important;
  color: #707070 !important;
  width: 100%;
  display: inline-table;
  padding: 3%;
  padding-left: 16%;
  margin: 0 !important;
  margin-left: 5px !important;
  cursor: pointer;
}

.btn-group {
  display: unset !important;
}

.btn-light {
  display: none !important;
}

input[type="checkbox"] {
  border: 1px solid #707070;
  display: inline-block;
  width: 16px;
  height: 16px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 2px;
  font-family: lato, sans-serif;
  cursor: pointer;
  position: relative;
  top: 3px;
  left: 40px;
}

input[type="checkbox"]:checked {
  border: 1px solid #044f68;
  background: #044f68
    url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48cG9seWxpbmUgcG9pbnRzPSIyMCA2IDkgMTcgNCAxMiI+PC9wb2x5bGluZT48L3N2Zz4=)
    no-repeat;
  background-position: 50% 50%;
}

.beta-form-check {
  margin-left: 16px;
}

.beta-label {
  display: unset !important;
  font-size: 12px !important;
  color: #707070 !important;
  margin-left: 5px;
  pointer-events: none;
}

.beta-input {
  left: 0 !important;
}
