$title_font: outfit, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI",
  "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  "Helvetica Neue", sans-serif;
$pharagraph-font: lato, sans-serif, -apple-system, BlinkMacSystemFont,
  "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
  "Droid Sans", "Helvetica Neue", sans-serif;

h1 {
  color: #333333;
  font-weight: 700 !important;
  font-size: 30px !important;
  padding-top: 20px;
  padding-bottom: 10px !important;
  margin-bottom: 10px;
  font-family: $title_font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2 {
  color: #333333;
  font-weight: 700 !important;
  font-size: 20px !important;
  padding-top: 20px;
  padding-bottom: 10px !important;
  margin-bottom: 10px;
  font-family: $title_font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h3 {
  color: #333333;
  font-weight: 700 !important;
  font-size: 16px !important;
  padding-top: 20px !important;
  padding-bottom: 10px !important;
  font-family: $title_font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  color: #333;
  padding-top: 0;
  font-weight: 400 !important;
  font-family: $pharagraph-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;

  font-family: $pharagraph-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
}

th {
  background-color: #eceff2;
  font-weight: 900 !important;
}
tr:nth-child(even) {
  background-color: #eceff2;
}

td,
th {
  border-bottom: 1px solid #ddd;
  padding: 10px;
  text-align: left;
  color: #333333;
  font-family: $pharagraph-font;
}

td {
  vertical-align: middle;
}

code {
  margin: 1em 0px;
  font-family: courier, monospace;
  background-color: #011025;
  color: #ececec !important;
  border-radius: 10px;
  position: relative;
  display: block;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 1.75em 1.6em 1.6em 1.6em;
}

em {
  padding: 2px 8px;
  margin: 2px;
  display: inline;
  color: #044f68;
  border-radius: 2px;
  border: 1px solid #d3d6d8;
  background-color: #eceff2 !important;
}

img {
  width: 100%;
  margin-top: 1.5rem;
}

img[src*="#screen"] {
  width: 300px;
  margin: auto;
  display: block;
}

ul {
  font-family: $pharagraph-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

li {
  padding: 0.5rem;
}

blockquote {
  font-family: $title_font;
  background: #eceff2;
  border-left: 10px solid #011025;
  margin: 1.5em 10px;
  border-radius: 4px;
  padding: 15px;
  quotes: "\201C""\201D""\2018""\2019";
}

blockquote p {
  font-family: $title_font;
  font-weight: 700 !important;
  display: inline;
}

@media (max-width: 600px) {
  table {
    font-size: 12px;
  }

  code {
    font-size: 13px;
  }
}

@media (max-width: 992px) {
  table {
    position: relative;
    display: block;
    overflow-x: auto;
  }

  img[src*="#screen"] {
    width: 80%;
  }
}
