@font-face {
  font-family: "outfit";
  src: url("https://cdn.finshark.io/fonts/Outfit/Outfit-Black.ttf")
    format("truetype");

  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "outfit";
  src: url("https://cdn.finshark.io/fonts/Outfit/Outfit-Bold.ttf")
    format("truetype");

  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "outfit";
  src: url("https://cdn.finshark.io/fonts/Outfit/Outfit-ExtraBold.ttf")
    format("truetype");

  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "outfit";
  src: url("https://cdn.finshark.io/fonts/Outfit/Outfit-SemiBold.ttf")
    format("truetype");

  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "outfit";
  src: url("https://cdn.finshark.io/fonts/Outfit/Outfit-ExtraLight.ttf")
    format("truetype");

  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "outfit";
  src: url("https://cdn.finshark.io/fonts/Outfit/Outfit-Light.ttf")
    format("truetype");

  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "outfit";
  src: url("https://cdn.finshark.io/fonts/Outfit/Outfit-Thin.ttf")
    format("truetype");

  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "outfit";
  src: url("https://cdn.finshark.io/fonts/Outfit/Outfit-Medium.ttf")
    format("truetype");

  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "outfit";
  src: url("https://cdn.finshark.io/fonts/Outfit/Outfit-Regular.ttf")
    format("truetype");

  font-weight: 400;
  font-style: normal;
}
