@import "../globals/colors";

.alert--color {
  background-color: #f9f5fb;
  border-color: #8f39b2 !important	;
}

.txt--link {
  text-decoration: underline;
  color: #26b261;
}

.c-red {
  color: #bf3030;
}
