.footer {
  font-family: outfit, sans-serif;
  background-color: #eceff2;
  position: relative;
  border-radius: 4px;
  padding: 1.75em 1.6em 1.6em 1.6em;
  &__navigation {
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    width: 100%;
    &--link {
      text-decoration: none;
      font-size: 14px;
      font-weight: 400;
      color: #011025;
      &:hover {
        color: #011025;
      }
    }
  }
}
