@import "./_colors.scss";
@import "./_font-lato.scss";
@import "./_font-outfit.scss";

body {
  font-family: lato, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* Typography */

/* Body */
$body-font: lato, sans-serif;
$body-weight: 400;
$title_font: outfit, sans-serif;
$pharagraph-font: lato, sans-serif;

/* Code block */
$code-font: courier, monospace;

/* Content titles */
$title-color: $title-color;
$title-weight: bolder;
$title-size: 22px;

/* Content subtitles */
$subtitle-color: $subtitle-color;
$subtitle-weight: bold;
$subtitle-size: 18px;

/* Content sub-subtitles */
$sub-subtitle-color: $sub-subtitle-color;
$sub-subtitle-weight: bolder;
$sub-subtitle-size: 15px;

p {
  padding-top: 14px;
  font-size: 15px;
  color: #333333;
}

#content {
  font-family: $body-font;
}
.content {
  &__title {
    color: $title-color;
    font-weight: $title-weight;
    font-size: $title-size;
    font-family: $title_font;
  }

  &__subtitle {
    font-size: $subtitle-size;
    font-weight: $subtitle-weight;
    color: $subtitle-color;
    font-family: $title_font;
  }

  &__sub-subtitle {
    font-size: $sub-subtitle-size;
    font-weight: $sub-subtitle-weight;
    color: $sub-subtitle-color;
    font-family: $title_font;

    &--blue {
      color: $subtitle-color;
      font-weight: $subtitle-weight;
      font-family: $title_font;
    }
  }
}
