@media (max-width: 600px) {
  .dropdown-menu {
    width: 92% !important;
  }

  .sidebar {
    margin-left: -18rem;
    height: 100%;
    padding-bottom: 10%;
  }

  #sidebar-toggle {
    grid-column: 3;
  }

  .container {
    margin-left: 0 !important;
  }

  .toggle {
    width: 40px;
    height: 40px;
    z-index: 1;
  }

  .sidebar-toggle-icon {
    width: 15px;
    height: 15px;
  }

  body main div button.toggle div svg.sidebar-toggle-icon {
    transform: rotate(180deg) !important;
  }

  body main div.toggle-collapsed button.toggle div svg.sidebar-toggle-icon {
    transform: rotate(0deg) !important;
  }

  .collapsed {
    margin-left: 0 !important;
  }

  .toggle-collapsed {
    margin-left: 18rem !important;
  }

  #root {
    grid-template-areas:
      "nav toggle header"
      "nav toggle article";
    grid-template-rows: auto;
    grid-template-columns: auto;
  }

  .filter-dropdown {
    margin-right: 0;
    width: 78vw !important;
  }

  .dropdown {
    width: 85vw !important;
  }

  .ml-auto {
    margin-left: unset !important;
  }
}

@media (max-width: 768px) {
  .beta-form-check {
    margin-left: 0;
  }

  .providers-table tbody th {
    font-weight: 100;
    font-style: italic;
    text-align: left;
    position: relative;
  }

  .providers-table th,
  .providers-table td {
    min-width: 25vw;
  }

  .providers-table thead th:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
  }

  .providers-table tbody td:first-child {
    position: sticky;
    left: 0;
    background-color: #f8faff;
    z-index: 1;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .filter-dropdown {
    margin-right: 0;
    width: 50vw !important;
  }

  .dropdown {
    width: 50vw !important;
  }
}

@media (max-width: 992px) {
  #content {
    padding-left: 25px !important;
    margin: 0 !important;
    box-sizing: border-box;
    overflow-x: auto;
  }
}

@media (min-width: 993px) {
  .container {
    margin-left: 60px !important;
  }
}
